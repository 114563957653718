import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Apresentacao.css";
import logo from "../../assets/images/logo-fabiana-carvalho-preto.png";

let disc;

export default class Apresentacao extends Component {
  constructor() {
    super();
    disc =
      window.location.pathname.slice(1).toLocaleLowerCase().indexOf("disc") ===
      0;
  }
  componentDidMount() {
    const url = window.origin + (disc ? "/disc" : "/eneagrama");
    window.history.replaceState(null, "", url);
  }

  clearStorage() {
    localStorage.setItem(`answer${disc ? "Disc" : "Eneagram"}`, "");
    localStorage.setItem(`user${disc ? "Disc" : "Eneagram"}`, "");
    localStorage.setItem(`resultState${disc ? "Disc" : "Eneagram"}`, "");
  }

  render() {
    return (
      <div className="apresentacao">
        <img src={logo} alt="Instituto do Conhecimento" />
        <div className="conteudo">
          {disc ? (
            <svg
              width="150"
              height="151"
              viewBox="0 0 150 151"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="75" cy="76" r="73.5" stroke="black" strokeWidth="3" />
              <line
                x1="75.5"
                y1="0.99"
                x2="76.5"
                y2="150.99"
                stroke="black"
                strokeWidth="3"
              />
              <line
                x1="0.000719391"
                y1="74.5"
                x2="150"
                y2="74.5719"
                stroke="black"
                strokeWidth="3"
              />
              <path
                d="M34.6309 65V33.7188H43.4609C46.1823 33.7188 48.5885 34.3203 50.6797 35.5234C52.7708 36.7266 54.3822 38.4382 55.5137 40.6582C56.6595 42.8783 57.2396 45.4277 57.2539 48.3066V50.3047C57.2539 53.2552 56.681 55.8405 55.5352 58.0605C54.4036 60.2806 52.778 61.985 50.6582 63.1738C48.5527 64.3626 46.0964 64.9714 43.2891 65H34.6309ZM38.7559 37.1133V61.627H43.0957C46.2754 61.627 48.7461 60.6387 50.5078 58.6621C52.2839 56.6855 53.1719 53.8711 53.1719 50.2188V48.3926C53.1719 44.8405 52.334 42.0833 50.6582 40.1211C48.9967 38.1445 46.6335 37.1419 43.5684 37.1133H38.7559Z"
                fill="black"
              />
              <path
                d="M44.8477 107.057C41.3099 106.04 38.7318 104.794 37.1133 103.318C35.5091 101.829 34.707 99.9954 34.707 97.8184C34.707 95.3548 35.6882 93.321 37.6504 91.7168C39.627 90.0983 42.1908 89.2891 45.3418 89.2891C47.4902 89.2891 49.4023 89.7044 51.0781 90.5352C52.7682 91.3659 54.0716 92.5117 54.9883 93.9727C55.9193 95.4336 56.3848 97.0306 56.3848 98.7637H52.2383C52.2383 96.873 51.6367 95.3906 50.4336 94.3164C49.2305 93.2279 47.5332 92.6836 45.3418 92.6836C43.3079 92.6836 41.7181 93.1348 40.5723 94.0371C39.4408 94.9251 38.875 96.1641 38.875 97.7539C38.875 99.0286 39.4121 100.11 40.4863 100.998C41.5749 101.872 43.4154 102.674 46.0078 103.404C48.6146 104.135 50.6484 104.944 52.1094 105.832C53.5846 106.706 54.6732 107.73 55.375 108.904C56.0911 110.079 56.4492 111.461 56.4492 113.051C56.4492 115.586 55.4609 117.62 53.4844 119.152C51.5078 120.671 48.8652 121.43 45.5566 121.43C43.4082 121.43 41.403 121.021 39.541 120.205C37.679 119.374 36.2396 118.243 35.2227 116.811C34.2201 115.378 33.7188 113.753 33.7188 111.934H37.8652C37.8652 113.824 38.5599 115.321 39.9492 116.424C41.3529 117.512 43.222 118.057 45.5566 118.057C47.7337 118.057 49.4023 117.613 50.5625 116.725C51.7227 115.837 52.3027 114.626 52.3027 113.094C52.3027 111.561 51.7656 110.38 50.6914 109.549C49.6172 108.704 47.6693 107.873 44.8477 107.057Z"
                fill="black"
              />
              <path
                d="M116.641 111.074C116.254 114.383 115.029 116.939 112.967 118.744C110.919 120.535 108.19 121.43 104.781 121.43C101.086 121.43 98.1211 120.105 95.8867 117.455C93.6667 114.805 92.5566 111.26 92.5566 106.82V103.812C92.5566 100.905 93.0723 98.3483 94.1035 96.1426C95.1491 93.9368 96.6243 92.2467 98.5293 91.0723C100.434 89.8835 102.64 89.2891 105.146 89.2891C108.469 89.2891 111.133 90.2201 113.139 92.082C115.144 93.9297 116.311 96.4935 116.641 99.7734H112.494C112.136 97.2812 111.355 95.4766 110.152 94.3594C108.964 93.2422 107.295 92.6836 105.146 92.6836C102.511 92.6836 100.441 93.6576 98.9375 95.6055C97.4479 97.5534 96.7031 100.325 96.7031 103.92V106.949C96.7031 110.344 97.4121 113.044 98.8301 115.049C100.248 117.054 102.232 118.057 104.781 118.057C107.073 118.057 108.827 117.541 110.045 116.51C111.277 115.464 112.093 113.652 112.494 111.074H116.641Z"
                fill="black"
              />
              <path d="M106.057 65H101.932V33.7188H106.057V65Z" fill="black" />
            </svg>
          ) : (
            <svg
              width="150"
              height="153"
              viewBox="0 0 150 153"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4699 113.25L75 3.07633L136.53 113.25H13.4699Z"
                stroke="black"
                strokeWidth="3"
              />
              <line
                x1="52.54"
                y1="146.344"
                x2="23.54"
                y2="23.3442"
                stroke="black"
                strokeWidth="3"
              />
              <line
                x1="25.5881"
                y1="21.6201"
                x2="147.588"
                y2="73.6201"
                stroke="black"
                strokeWidth="3"
              />
              <line
                y1="-1.5"
                x2="132.62"
                y2="-1.5"
                transform="matrix(-0.919923 0.392098 0.392098 0.919923 125 23)"
                stroke="black"
                strokeWidth="3"
              />
              <line
                x1="52.7937"
                y1="144.892"
                x2="1.79373"
                y2="75.8916"
                stroke="black"
                strokeWidth="3"
              />
              <line
                y1="-1.5"
                x2="126.372"
                y2="-1.5"
                transform="matrix(0.22948 -0.973313 -0.973313 -0.22948 96 146)"
                stroke="black"
                strokeWidth="3"
              />
              <line
                y1="-1.5"
                x2="85.8021"
                y2="-1.5"
                transform="matrix(0.594391 -0.804176 -0.804176 -0.594391 96 144)"
                stroke="black"
                strokeWidth="3"
              />
              <circle cx="75" cy="75" r="73.5" stroke="black" strokeWidth="3" />
            </svg>
          )}
          <h2 className="titulo-pequeno">Descubra sua</h2>
          <h1 className="titulo-grande">Personalidade</h1>
          {disc ? (
            <p className="subtitulo">
              Faça um teste <strong>DISC</strong> gratuitamente
            </p>
          ) : (
            <p className="subtitulo">
              Faça um teste grátis de <strong>Eneagrama</strong>
            </p>
          )}
        </div>
        <Link
          to={disc ? "/disc/instrucoes" : "/eneagrama/instrucoes"}
          onClick={this.clearStorage}
        >
          <div className="button">iniciar teste</div>
        </Link>
      </div>
    );
  }
}
