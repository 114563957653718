import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import api from "../../util/api";
import iconNext from "../../assets/icons/iconfinder_next_308956.svg";
import vencedor from "../../assets/icons/iconfinder_medal_1118195.svg";
import poderoso from "../../assets/icons/iconfinder_m-28_4230541.svg";
import dominante from "../../assets/icons/iconfinder_m-28_4230541.svg";
import otimista from "../../assets/icons/iconfinder_b-91_4230288.svg";
import analítico from "../../assets/icons/iconfinder_search_461380.svg";
import perfeccionista from "../../assets/icons/iconfinder_diamond_430094.svg";
import intenso from "../../assets/icons/fire.png";
import precavido from "../../assets/icons/iconfinder_Vector-icons_39_1041628.svg";
import mediador from "../../assets/icons/iconfinder_Meditation_4042272.svg";
import estável from "../../assets/icons/iconfinder_Meditation_4042272.svg";
import ajudante from "../../assets/icons/iconfinder_property-agent-005_1851811.svg";
import cauteloso from "../../assets/icons/iconfinder_scales_296210.png";
import influente from "../../assets/icons/iconfinder_14_leader_target_achievement_business_3964125.png";
import whatsapp from "../../assets/icons/whatsapp.png";

import "./Resultados.css";

const profileTranslation = {
  enneagram: {
    perfectionist: "Perfeccionista",
    helper: "Ajudante",
    achiever: "Vencedor",
    romantic: "Intenso",
    investigator: "Analítico",
    loyalist: "Precavido",
    enthusiast: "Otimista",
    challanger: "Poderoso",
    mediator: "Mediador",
  },
  disc: {
    dominance: "Dominante",
    influence: "Influente",
    steadiness: "Estável",
    conscientiousness: "Cauteloso",
  },
};

let disc;

function resizeVideoHeight() {
  const video = document.querySelector(".resultados iframe");
  if (video) {
    const width = parseInt(window.getComputedStyle(video).width);
    video.style.height = width * (9 / 16) + "px";
  }
}

function decodeString(str) {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    console.error("Error decoding string: ", e);
    return str;
  }
}

window.addEventListener("resize", resizeVideoHeight);

const icons = {
  vencedor,
  poderoso,
  otimista,
  analítico,
  perfeccionista,
  intenso,
  precavido,
  mediador,
  ajudante,
  estável,
  dominante,
  cauteloso,
  influente,
};

export default class Resultados extends Component {
  constructor() {
    super();
    disc =
      window.location.pathname.slice(1).toLocaleLowerCase().indexOf("disc") >=
      0;
    this.getResult(disc);
    this.answer = JSON.parse(
      localStorage.getItem(`answer${disc ? "Disc" : "Eneagram"}`) || "{}"
    );
    this.state = {
      index:
        parseInt(
          localStorage.getItem(`resultState${disc ? "Disc" : "Eneagram"}`)
        ) || 0,
      typesList: null,
    };
    this.resultsList = this.orderResults();
  }

  getResult(isDisc) {
    api
      .get(`/profile/${isDisc ? "disc" : "enneagram"}`)
      .then((res) => {
        this.setState({ ...this.state, typesList: res.data.profiles });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  prevResult() {
    if (this.state.index > 0) {
      this.setState({ index: this.state.index - 1 });
      localStorage.setItem(
        `resultState${disc ? "Disc" : "Eneagram"}`,
        this.state.index - 1
      );
    }
  }

  nextResult() {
    if (this.state.index < this.resultsList.length) {
      this.setState({ index: this.state.index + 1 });
      localStorage.setItem(
        `resultState${disc ? "Disc" : "Eneagram"}`,
        this.state.index + 1
      );
    }
  }

  orderResults() {
    let answer = this.answer;
    delete answer.length;

    let list = Object.keys(answer).map((k) => {
      answer[k].type = k;
      return answer[k];
    });

    for (let i = 1; i < list.length; i++) {
      for (let j = i - 1; j >= 0; j--) {
        if (list[j + 1].percentage > list[j].percentage) {
          const temp = list[j + 1];
          list[j + 1] = list[j];
          list[j] = temp;
        } else {
          break;
        }
      }
    }

    return list;
  }

  componentDidMount() {
    this.adjustControllerMargin();
    window.addEventListener("resize", this.adjustControllerMargin);
    resizeVideoHeight();

    const descriptionParagraph = document.querySelector(
      ".resultados .descricao p"
    );
    if (descriptionParagraph) descriptionParagraph.innerHTML = this.descricao;

    document.body.scrollTop = 0; // for safari
    document.documentElement.scrollTop = 0; // for the rest
  }

  componentDidUpdate() {
    resizeVideoHeight();
    const descriptionParagraph = document.querySelector(
      ".resultados .descricao p"
    );
    if (descriptionParagraph) descriptionParagraph.innerHTML = this.descricao;

    document.body.scrollTop = 0; // for safari
    document.documentElement.scrollTop = 0; // for the rest
  }

  adjustControllerMargin() {
    const controller = document.querySelector(
      ".resultados-container .controle"
    );
    if (controller) {
      const marginLeft =
        (window.innerWidth -
          parseInt(window.getComputedStyle(controller).width)) /
        2;
      controller.style.marginLeft = marginLeft + "px";
    }
  }

  findProfile(answer, isDisc = false) {
    if (this.state.typesList && answer) {
      const translation = isDisc
        ? profileTranslation.disc
        : profileTranslation.enneagram;
      const profile = this.state.typesList.find(
        (e) =>
          translation[e.profile].toLocaleLowerCase() ===
          answer.type.toLocaleLowerCase()
      );
      return profile || {};
    }
    return {};
  }

  render() {
    const answer = this.resultsList[
      this.state.index < this.resultsList.length ? this.state.index : 0
    ] || { type: "" };
    const result = this.findProfile(answer, disc);
    this.descricao = decodeString(result.description);

    return !localStorage.getItem(`user${disc ? "Disc" : "Eneagram"}`) ? (
      <Redirect to={disc ? "/disc/avaliacao" : "/eneagrama/avaliacao"} />
    ) : (
      <>
        {" "}
        {this.state.index < this.resultsList.length ? (
          <div className="resultados-container">
            <div className="resultados">
              <div className="cabecalho">
                <h1>Resultados</h1>
                {disc ? (
                  <p>
                    Os primeiros resultados têm mais chance de ser o seu perfil
                    de personalidade
                  </p>
                ) : (
                  <p>Provalvelmente o seu eneatipo é um dos 3 primeiros</p>
                )}
              </div>
              <div className="conteudo-principal">
                <div
                  className="resultado"
                  data-precision={answer.percentage < 50 ? "low" : "high"}
                >
                  <img
                    className="icon"
                    src={icons[answer.type.toLowerCase()]}
                    alt={answer.type}
                  ></img>
                  <div className="percentual"> {answer.percentage + "%"}</div>
                  <div className="tipo">{answer.type}</div>
                  <div className="acertos">
                    <div className="acertos-fundo">
                      <div
                        className="acertos-barra"
                        style={{ width: answer.percentage + "%" }}
                      ></div>
                    </div>
                    <div className="acertos-percentual">
                      {answer.points + " / " + answer.total}
                    </div>
                  </div>
                </div>
                <div className="descricao">
                  <p></p>
                </div>
                <div className="community-description">
                  Você assistiu o vídeo e se identificou com essa personalidade?{" "}
                  <a href={result.community_url}>Entre na sua comunidade</a>.
                </div>
                {/* <iframe
                  title={result.type}
                  src={result.video_url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe> */}
              </div>
            </div>
            <div className="controle-container">
              <div className="controle">
                <img
                  onClick={this.prevResult.bind(this)}
                  className="previous-button"
                  alt="previous"
                  src={iconNext}
                  data-status={this.state.index === 0 ? "disabled" : ""}
                />
                <span className="pagina-progresso">
                  {this.state.index + 1} / {this.resultsList.length + 1}
                </span>
                <img
                  onClick={this.nextResult.bind(this)}
                  className="next-button"
                  alt="next"
                  src={iconNext}
                  data-status={
                    this.state.index === this.resultsList.length
                      ? "disabled"
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="resultados-container">
            <div className="resultados veja-mais">
              <div className="cabecalho">
                <h1>
                  Deseja aprimorar
                  <br />
                  sua Personalidade?
                </h1>
                <p>Junte-se as comunidades</p>
                <div className="community-cards-grid">
                  {this.resultsList?.map((result) => (
                    <CommunityCard
                      profile={this.findProfile(result)}
                      type={result.type}
                    />
                  ))}
                </div>
                <div className="button-wrapper">
                  <a
                    href="https://institutofabianacarvalho.com.br/"
                    title="instituto Fabiana Carvalho"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Instituto Fabiana Carvalho
                  </a>
                </div>
              </div>
            </div>
            <div className="controle-container">
              <div className="controle">
                <img
                  onClick={this.prevResult.bind(this)}
                  className="previous-button"
                  alt="previous"
                  src={iconNext}
                  data-status={this.state.index === 0 ? "disabled" : ""}
                />
                <span className="pagina-progresso">
                  {this.state.index + 1} / {this.resultsList.length + 1}
                </span>
                <img
                  onClick={this.nextResult.bind(this)}
                  className="next-button"
                  alt="next"
                  src={iconNext}
                  data-status={
                    this.state.index === this.resultsList.length
                      ? "disabled"
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

class CommunityCard extends Component {
  render() {
    return (
      <a
        className="community-card"
        href={this.props.profile.community_url}
        title={"Comunidade do tipo " + this.props.type}
        target="_blank"
      >
        <img src={icons[this.props.type.toLowerCase()]} alt={this.props.type} />

        <div className="community-type-container">
          <span>{this.props.type}</span>

          <div className="whatsapp-container">
            <img src={whatsapp} alt="Whatsapp" />
            <span>Comunidade</span>
          </div>
        </div>
      </a>
    );
  }
}
