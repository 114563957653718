function isDevelopment() {
  return window.location.href.indexOf("localhost") >= 0;
}

const developmentConfig = {
  url: {
    login: "http://login.localhost",
    panel: "http://panel.localhost",
    test: "http://test.localhost",
    api: "http://api.localhost",
  },
};

const productionConfig = {
  url: {
    panel: `https://eneagrama-painel.institutofabianacarvalho.com.br`,
    test: `https://eneagrama.institutofabianacarvalho.com.br`,
    api: `https://eneagrama-api.institutofabianacarvalho.com.br`,
  },
};

export default isDevelopment() ? developmentConfig : productionConfig;
